import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RandomGuard } from './service/guard/random.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'task',
    loadChildren: () =>
      import('./task-module/task-module-calculator.module').then(
        (m) => m.TaskModuleCalculatorModule
      ),
    canActivate: [RandomGuard],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [RandomGuard],
  },
  // {
  //   path: 'methane-calculator',
  //   loadChildren: () =>
  //     import('./methane-calculator/methane-calculator.module').then(
  //       (m) => m.MethaneCalculatorModule
  //     ),
  //   canActivate: [RandomGuard],
  // },
  // {
  //   path: 'herd-methane-calculator',
  //   loadChildren: () =>
  //     import('./herd-methane-calculator/herd-methane-calculator.module').then(
  //       (m) => m.HerdMethaneCalculatorModule
  //     ),
  //   canActivate: [RandomGuard],
  // },
  {
    path: 'sign-up',
    loadChildren: () =>
      import('./sign-up/sign-up.module').then((m) => m.SignUpModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'methane-simulator',
    loadChildren: () =>
      import('./methane-simulator/methane-simulator.module').then(
        (m) => m.MethaneSimulatorModule
      ),
    canActivate: [RandomGuard],
  },
  {
    path: 'methane-emission-map',
    loadChildren: () =>
      import('./satelite-emission-map/methane-emission-map.module').then(
        (m) => m.MethaneEmissionMapModule
      ),
    canActivate: [RandomGuard],
  },
  {
    path: 'map-farm-analytics',
    loadChildren: () =>
      import('./GIS-CH4-farm-analytics/map-farm-analytics.module').then(
        (m) => m.MapFarmAnalyticsModule
      ),
    canActivate: [RandomGuard],
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  // imports: [
  //   RouterModule.forRoot(routes, {
  //     useHash: true,
  //   }),
  // ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
