<div class="plans_nav_container df jc afs fdc">
  <div class="nav">
    <div
      [ngClass]="{ active: tab == activeComponent }"
      class="nav-item"
      *ngFor="let tab of tabs; let i = index"
      (click)="activateTab(tab)"
    >
      {{ tab.tabName }}
    </div>
  </div>
  <ng-template
    [ngTemplateOutlet]="activeComponent.templateRef"
    [ngTemplateOutletContext]="{ content: activeComponent.content }"
  ></ng-template>
</div>
