<div class="delete_modal_container df ac jfs fdc">
  <div class="close_container df jsb ac">
    <div></div>
    <svg
      (click)="close()"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 6L6.5 18"
        stroke="#25282B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.5 6L18.5 18"
        stroke="#141414"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
  <div class="content_container df fdc jfs ac">
    <svg
      width="55"
      height="54"
      viewBox="0 0 55 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0012 23.4393H13.9961V25.7433H26.3053V8.48633H24.0012V23.4393Z"
        fill="#141414"
      />
      <path
        d="M48.5268 28.8876C49.5472 23.8078 48.9057 18.5345 46.6973 13.8474C44.4888 9.1604 40.8306 5.30852 36.2636 2.86142C31.6966 0.414321 26.4634 -0.49807 21.3377 0.259176C16.2121 1.01642 11.4662 3.4031 7.80192 7.06626C4.13765 10.7294 1.74953 15.4746 0.99072 20.6C0.231914 25.7254 1.14271 30.9589 3.58842 35.5267C6.03413 40.0944 9.8849 43.7538 14.5713 45.9637C19.2576 48.1736 24.5308 48.8166 29.6109 47.7978C30.751 49.4131 32.2303 50.7598 33.9453 51.7436C35.6603 52.7275 37.5696 53.3247 39.5396 53.4934C41.5095 53.6622 43.4925 53.3985 45.3499 52.7207C47.2073 52.043 48.8941 50.9676 50.2924 49.5697C51.6907 48.1718 52.7666 46.4853 53.445 44.6282C54.1233 42.771 54.3876 40.788 54.2194 38.818C54.0512 36.848 53.4546 34.9386 52.4713 33.2233C51.488 31.508 50.1418 30.0282 48.5268 28.8876ZM24.9107 45.9661C20.5991 45.9695 16.3834 44.6941 12.7968 42.3012C9.21025 39.9083 6.41392 36.5055 4.76159 32.5231C3.10926 28.5407 2.67516 24.1577 3.51421 19.9286C4.35326 15.6995 6.42775 11.8142 9.47528 8.76425C12.5228 5.7143 16.4064 3.63673 20.6349 2.79433C24.8634 1.95192 29.2467 2.38255 33.2304 4.03172C37.214 5.68089 40.6191 8.47451 43.0149 12.0592C45.4106 15.6439 46.6893 19.8586 46.6893 24.1702C46.6896 25.3373 46.5971 26.5025 46.4128 27.655C43.8894 26.479 41.0653 26.1076 38.3235 26.5911C35.5818 27.0747 33.0551 28.3898 31.0862 30.3581C29.1173 32.3264 27.8014 34.8526 27.317 37.5942C26.8325 40.3358 27.203 43.16 28.3782 45.6838C27.226 45.8702 26.0607 45.9646 24.8934 45.9661H24.9107ZM40.7047 51.2422C38.4731 51.2457 36.2907 50.587 34.4336 49.3495C32.5765 48.1121 31.1283 46.3516 30.2722 44.2907C29.4161 42.2299 29.1906 39.9614 29.6243 37.7724C30.058 35.5833 31.1313 33.5722 32.7085 31.9934C34.2856 30.4146 36.2957 29.3392 38.4843 28.9033C40.6729 28.4674 42.9416 28.6905 45.0033 29.5445C47.065 30.3985 48.8271 31.8449 50.0664 33.7007C51.3057 35.5565 51.9666 37.7383 51.9655 39.9699C51.9609 42.9581 50.7718 45.8226 48.6589 47.9356C46.5459 50.0486 43.6814 51.2377 40.6931 51.2422H40.7047Z"
        fill="#141414"
      />
      <path d="M41.845 32.5527H39.541V43.3528H41.845V32.5527Z" fill="#141414" />
      <path d="M41.845 45.2246H39.541V47.9606H41.845V45.2246Z" fill="#141414" />
    </svg>

    <h2>Your Free trial has expired</h2>
    <p>Your 7 days free trial has expired.</p>

    <p>
      You no longer have access to the Vetvantage Features. Let’s change that,
      to continue using Vetvantage, you will need to upgrade you plan to premium
    </p>
    <div class="btn_container df jc ac">
      <a
        class="draw_btn first df ac jc"
        href="https://calendar.app.google/5crXoHybAySLFGjQ9"
        target="_blank"
        >Talk to us</a
      >
      <button
        type="button"
        class="draw_btn second"
        (click)="enableSubscription()"
      >
        Continue, Subscribe now
      </button>
    </div>
  </div>
</div>
