import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ApiService } from '../service/api/api.service';
import { ThanksModalComponent } from '../thanks_modal/thanks_modal.component';

@Component({
  selector: 'vetvantage-subscription-expire-modal',
  templateUrl: './subscription_expire_modal.component.html',
  styleUrl: './subscription_expire_modal.component.scss',
})
export class SubscriptionExpireModalComponent {
  constructor(
    public dialogRef: MatDialogRef<SubscriptionExpireModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT) private readonly document: Document,
    public apiService: ApiService,
    private dialog: MatDialog
  ) {}
  close() {
    this.dialogRef.close();
  }
  enableSubscription() {
    this.apiService
      .subscriptionRequest({ period: 'MONTHLY', type: 'PRO' })
      .subscribe(
        (res) => {
          if (res.statusCode == 10000) {
            this.dialogRef.close();
            this.dialog.open(ThanksModalComponent, {});
          }
        },
        (error) => {
          this.dialogRef.close();
        }
      );
  }
}
