import { Component, Input, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-tab-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ui-tab-item.component.html',
})
export class UITabItemComponent {
  @Input() tabName? = 'default';
  @Input() templateRef!: TemplateRef<any>;
  @Input() content?: any;
}
