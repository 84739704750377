import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../api/data.service';
import { ApiService } from '../../api/api.service';
import { BehaviorSubject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { ThanksModalComponent } from 'vetvantage-frontend/src/app/thanks_modal/thanks_modal.component';
import * as moment from 'moment';
@Component({
  selector: 'vetvantage-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class MenuComponent implements OnInit, AfterViewInit {
  isLoginPage: any = false;
  progress$ = new BehaviorSubject<boolean>(false);
  isExpanded = false;
  enableEditFlag = false;
  editForm: FormGroup | any;
  userInfo: any;
  imgUrl: any = '';
  isSmallScreen = this.breakpointObserver.isMatched('(max-width: 599px)');
  mapSvgColorChangeValue = false;
  moment = moment;
  planData = [
    {
      period: 'ANNUALLY',
      plan_name: 'Free',
      plan_amount: 0,
      planList: [
        'Methane Calculator for single animal',
        'Satellite imagery for CH4 region wise',
      ],
      btn_name: 'Already using',
    },
    {
      period: 'ANNUALLY',
      plan_name: 'PRO',
      plan_amount: 20,
      planList: [
        'Methane Calculator for single animal',
        'Methane Calculator for herd',
        'Satellite imagery for CH4 region wise',
        'Satellite imagery for CH4 Farm analytics',
      ],
      btn_name: 'Choose plan',
    },
  ];
  planDataForQuaterly = [
    {
      period: 'QUATERLY',
      plan_name: 'Free',
      plan_amount: 0,
      planList: [
        'Methane Calculator for single animal',
        'Satellite imagery for CH4 region wise',
      ],
      btn_name: 'Already using',
    },
    {
      period: 'QUATERLY',
      plan_name: 'PRO',
      plan_amount: 20,
      planList: [
        'Methane Calculator for single animal',
        'Methane Calculator for herd',
        'Satellite imagery for CH4 region wise',
        'Satellite imagery for CH4 Farm analytics',
      ],
      btn_name: 'Choose plan',
    },
  ];
  planDataForMonthly = [
    {
      period: 'MONTHLY',
      plan_name: 'Free',
      plan_amount: 0,
      planList: [
        'Methane Calculator for single animal',
        'Satellite imagery for CH4 region wise',
      ],
      btn_name: 'Already using',
    },
    {
      period: 'MONTHLY',
      plan_name: 'PRO',
      plan_amount: 20,
      planList: [
        'Methane Calculator for single animal',
        'Methane Calculator for herd',
        'Satellite imagery for CH4 region wise',
        'Satellite imagery for CH4 Farm analytics',
      ],
      btn_name: 'Choose plan',
    },
  ];
  subscribed = false;
  freeVersionDaysLeft = 0;
  hideNavBar = false;
  constructor(
    private readonly fb: FormBuilder,
    private dataService: DataService,
    public apiService: ApiService,
    private router: Router,
    private readonly elem: ElementRef,
    private matSnackBar: MatSnackBar,
    private readonly breakpointObserver: BreakpointObserver,
    private dialog: MatDialog
  ) {}

  ngAfterViewInit(): void {
    this.router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd) {
        if (
          this.router.url === '/task' ||
          this.router.url === '/home' ||
          this.router.url === '/methane-emission-map' ||
          this.router.url === '/login' ||
          this.router.url === '/sign-up' ||
          this.router.url === '/methane-simulator'
        ) {
          this.hideNavBar = false;
        } else {
          this.hideNavBar = true;
        }
      }
    });
    this.dataService.getmessageProfile.subscribe((msg) => {
      if (msg) {
        const element =
          this.elem.nativeElement.querySelector('.profile_container').style
            .display;
        const elementOfplans =
          this.elem.nativeElement.querySelector('.plans_container').style
            .display;
        if (element == 'block') {
          this.elem.nativeElement.querySelector(
            '.profile_container'
          ).style.display = 'none';
        }
        if (elementOfplans == 'block') {
          this.elem.nativeElement.querySelector(
            '.plans_container'
          ).style.display = 'none';
        }
      }
    });
    this.apiService.getSubscriptionDetail(this.editForm.value).subscribe(
      (res) => {
        if (res.statusCode == 10000) {
          let date1 = res.data.endDate;
          let date2 = new Date();
          const diffTime = date1 - date2.getTime();
          const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
          if (res.data.type == 'TRIAL' && diffDays > 0) {
            this.freeVersionDaysLeft = diffDays;
            this.dataService.setSubscription('subscribed');
            this.subscribed = false;
          } else if (res.data.type == 'PRO' && diffDays > 0) {
            this.dataService.setSubscription('subscribed');
            this.subscribed = true;
          } else {
            this.dataService.setSubscription('notsubscribed');
            this.freeVersionDaysLeft = 0;
          }
        }
      },
      (error) => {}
    );
    this.getData();
    this.getImage();
  }
  public toggleMenu() {
    if (this.isExpanded) {
      const mySidenav: HTMLElement | any = document.getElementById('mySidenav');
      mySidenav.style.width = '14.5rem';
      const main: HTMLElement | any = document.getElementById('main');
      main.style.marginLeft = '14.5rem';
      // For profile menu
      this.elem.nativeElement.querySelector('.profile_container').style.left =
        '14.5rem';
      this.elem.nativeElement.querySelector('.profile_container').style.width =
        '90%';
      // For plan container
      this.elem.nativeElement.querySelector('.plans_container').style.left =
        '14.5rem';
      this.elem.nativeElement.querySelector('.plans_container').style.width =
        '90%';
      this.dataService.setMessage(false);
      setTimeout(() => {
        this.isExpanded = false;
      }, 100);
    } else {
      const mySidenav: HTMLElement | any = document.getElementById('mySidenav');
      mySidenav.style.width = '6rem';
      const main: HTMLElement | any = document.getElementById('main');
      main.style.marginLeft = '6rem';

      // For profile menu

      this.elem.nativeElement.querySelector('.profile_container').style.left =
        '6rem';
      this.elem.nativeElement.querySelector('.profile_container').style.width =
        '100%';
      // For plan container
      this.elem.nativeElement.querySelector('.plans_container').style.left =
        '6rem';
      this.elem.nativeElement.querySelector('.plans_container').style.width =
        '100%';
      this.dataService.setMessage(true);
      this.isExpanded = true;
    }
  }
  openProfile(): void {
    const element =
      this.elem.nativeElement.querySelector('.profile_container').style.display;
    if (!element) {
      this.elem.nativeElement.querySelector(
        '.profile_container'
      ).style.display = 'block';
    }
    if (element == 'none') {
      this.elem.nativeElement.querySelector(
        '.profile_container'
      ).style.display = 'block';
    }
    if (element == 'block') {
      this.elem.nativeElement.querySelector(
        '.profile_container'
      ).style.display = 'none';
    }
  }
  close() {
    const element =
      this.elem.nativeElement.querySelector('.profile_container').style.display;
    if (element == 'block') {
      this.elem.nativeElement.querySelector(
        '.profile_container'
      ).style.display = 'none';
    }
    const element2 =
      this.elem.nativeElement.querySelector('.plans_container').style.display;
    if (element2 == 'block') {
      this.elem.nativeElement.querySelector('.plans_container').style.display =
        'none';
    }
  }
  openPlans(): void {
    const element =
      this.elem.nativeElement.querySelector('.plans_container').style.display;
    if (!element) {
      this.elem.nativeElement.querySelector('.plans_container').style.display =
        'block';
    }
    if (element == 'none') {
      this.elem.nativeElement.querySelector('.plans_container').style.display =
        'block';
    }
    if (element == 'block') {
      this.elem.nativeElement.querySelector('.plans_container').style.display =
        'none';
    }
  }
  async ngOnInit() {
    this.editForm = this.fb.group({
      firstName: ['', Validators.required],
    });
    this.isLoginPage = await this.apiService.isLoggedIn();
    this.dataService.getmessageScrollTop.subscribe((msg) => {
      if (msg) {
        this.isLoginPage = !msg;
      }
    });
  }
  getData(): void {
    this.apiService.getUser().subscribe(
      (res) => {
        if (res.statusCode == 10000) {
          this.userInfo = res.data;
          this.getProfileImage();
        } else {
          this.userInfo = [];
        }
      },
      (error) => {
        // this.apiService.logout();
        // this.userInfo = [];
      }
    );
  }
  getImage() {
    this.apiService.getImageUrl().subscribe((res: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(res);
      reader.onload = (_event) => {
        const url = reader.result;
        this.imgUrl = url;
      };
    });
  }
  uploadFile(): void {
    const imagePayload = new FormData();
    const file = this.elem.nativeElement.querySelector('#uploadFileForImage')
      .files[0];
    if (file !== undefined) {
      const imageFile = new File([file], file.name, {
        type: file.type,
      });
      imagePayload.append('vetvantage', imageFile);
      this.apiService.uploadProfileImage(imagePayload).subscribe(
        (res) => {
          if (res.status == 200) {
            this.getData();
            this.getImage();
          }
        },
        (error) => {
          this.matSnackBar.open(error.error.message, 'X', {
            duration: 3600,
            horizontalPosition: 'right',
            verticalPosition: 'top',
          });
        }
      );
    }
  }
  uploadImage() {
    this.elem.nativeElement.querySelector('#uploadFileForImage').click();
  }
  logOut() {
    this.apiService.logout();
  }
  getProfileImage(): string {
    const imgUrl = this.userInfo?.profileUrl;
    const name = this.userInfo?.firstName;
    if (!imgUrl) {
      return name?.charAt(0).toUpperCase();
    }
    return '';
  }
  submitEditFarm() {
    this.apiService.updateProfile(this.editForm.value).subscribe(
      (res) => {
        if (res.statusCode == 10000) {
          this.getData();
          this.enableEditFlag = false;
        }
      },
      (error) => {
        this.matSnackBar.open(error.error.message, 'X', {
          duration: 3600,
          horizontalPosition: 'right',
          verticalPosition: 'top',
        });
      }
    );
  }
  enableEdit() {
    this.enableEditFlag = !this.enableEditFlag;
    this.editForm.controls['firstName'].setValue(this.userInfo.firstName);
  }
  cancelEdit() {
    this.enableEditFlag = !this.enableEditFlag;
    this.editForm.controls['firstName'].setValue('');
  }
  enableSubscription(data: any) {
    this.apiService
      .subscriptionRequest({ period: data.period, type: data.plan_name })
      .subscribe(
        (res) => {
          if (res.statusCode == 10000) {
            this.dialog.open(ThanksModalComponent, {});
          }
        },
        (error) => {}
      );
  }
  moveToleft() {
    this.router.navigate(['task']);
  }
}
