import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MenuComponent } from './service/shared/menu/menu.component';
import { MatListModule } from '@angular/material/list';
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  NgxUiLoaderHttpModule,
  NgxUiLoaderRouterModule,
} from 'ngx-ui-loader';
import { HttpInterceptorService } from '../app/service/interceptor/interceptor.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { initializeApp } from 'firebase/app';
import { environment } from '../environments/environment';
import { UITabItemComponent } from './service/shared/menu/ui-tab-item/ui-tab-item.component';
import { UITabsComponent } from './service/shared/menu/ui-tabs/ui-tabs.component';
import { SubscriptionExpireModalComponent } from './subscription_expire_modal/subscription_expire_modal.component';
import { ThanksModalComponent } from './thanks_modal/thanks_modal.component';
import { MatDialogModule } from '@angular/material/dialog';
initializeApp(environment.firebaseConfig);
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fastFadeOut: true,
  fgsColor: '#374f2e',
  fgsPosition: 'center-center',
  fgsSize: 60,
  fgsType: 'circle',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 120,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: '#ffffff',
  pbColor: '#f37721',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: false,
  text: '',
  textColor: '#ffffff',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 300,
};
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    SubscriptionExpireModalComponent,
    ThanksModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    HttpClientModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatSnackBarModule,
    NgxUiLoaderRouterModule,
    UITabItemComponent,
    UITabsComponent,
    MatDialogModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({
      exclude: [
        '/methane-emission-map',
        'http://127.0.0.1:3000/api/users/getUserProfile',
        'http://127.0.0.1:3000/api/users/viewMedia',
        'http://127.0.0.1:3000/api/admin0',
        'http://127.0.0.1:3000/api/admin1',
        'http://127.0.0.1:3000/api/admin2',
        'http://127.0.0.1:3000/api/view/filteredMap',
        'http://127.0.0.1:3000/api/view/methane/filteredMap',
        'http://127.0.0.1:3000/api/history/graph',
        'http://127.0.0.1:3000/api/getConstantDate',
        'http://127.0.0.1:3000/api/selectFarm/area',
        'http://127.0.0.1:3000/api/selectedArea/historyGraph/methane',
        'http://127.0.0.1:3000/api/selectedArea/historyGraph/ndvi',
        'http://127.0.0.1:3000/api/subscription/detail',
        'http://127.0.0.1:3000/api/farmCategory/paginate',
        'http://127.0.0.1:3000/api/farmCategory/userFarms/paginated',
        'http://127.0.0.1:3000/api/farmCategory/userFarms/dasboard',
        'http://127.0.0.1:3000/api/farmCategory/userFarms/centrePoints',
        'http://127.0.0.1:3000/api/avgSoilMoistureGraph',
        'http://127.0.0.1:3000/api/avgSoilMoisture',
        'http://127.0.0.1:3000/api/getSoilTypeData',
        'http://127.0.0.1:3000/api/getFarmDetail',
        'http://127.0.0.1:3000/api/getMilkAndCensusData',
        'http://127.0.0.1:3000/api/ndvi/hectors',
        'http://127.0.0.1:3000/api/weatherData/report',
        'http://127.0.0.1:3000/api/weatherHistory',
        'http://127.0.0.1:3000/api/chemicalData/report',
        'http://127.0.0.1:3000/api/animated/methane/history',
        'http://127.0.0.1:3000/api/animated/ndvi/history',
        'http://127.0.0.1:3000/api/animated/soil/history',
        'http://127.0.0.1:3000/api/getMethaneData',
        'http://127.0.0.1:3000/api/getNdviData',
        'http://127.0.0.1:3000/api/getAvgSoilData',

        'https://app.vetvantage.ai/api/users/getUserProfile',
        'https://app.vetvantage.ai/api/users/viewMedia',
        'https://app.vetvantage.ai/api/admin0',
        'https://app.vetvantage.ai/api/admin1',
        'https://app.vetvantage.ai/api/admin2',
        'https://app.vetvantage.ai/api/view/filteredMap',
        'https://app.vetvantage.ai/api/view/methane/filteredMap',
        'https://app.vetvantage.ai/api/history/graph',
        'https://app.vetvantage.ai/api/getConstantDate',
        'https://app.vetvantage.ai/api/selectFarm/area',
        'https://app.vetvantage.ai/api/selectedArea/historyGraph/methane',
        'https://app.vetvantage.ai/api/selectedArea/historyGraph/ndvi',
        'https://app.vetvantage.ai/api/subscription/detail',
        'https://app.vetvantage.ai/api/farmCategory/paginate',
        'https://app.vetvantage.ai/api/farmCategory/userFarms/paginated',
        'https://app.vetvantage.ai/api/farmCategory/userFarms/dasboard',
        'https://app.vetvantage.ai/api/farmCategory/userFarms/centrePoints',
        'https://app.vetvantage.ai/api/avgSoilMoistureGraph',
        'https://app.vetvantage.ai/api/avgSoilMoisture',
        'https://app.vetvantage.ai/api/getSoilTypeData',
        'https://app.vetvantage.ai/api/getFarmDetail',
        'https://app.vetvantage.ai/api/getMilkAndCensusData',
        'https://app.vetvantage.ai/api/ndvi/hectors',
        'https://app.vetvantage.ai/api/weatherData/report',
        'https://app.vetvantage.ai/api/weatherHistory',
        'https://app.vetvantage.ai/api/chemicalData/report',
        'https://app.vetvantage.ai/api/animated/methane/history',
        'https://app.vetvantage.ai/api/animated/ndvi/history',
        'https://app.vetvantage.ai/api/animated/soil/history',
        'https://app.vetvantage.ai/api/getMethaneData',
        'https://app.vetvantage.ai/api/getNdviData',
        'https://app.vetvantage.ai/api/getAvgSoilData',

        'https://dev.efeed.in/api/users/getUserProfile',
        'https://dev.efeed.in/api/users/viewMedia',
        'https://dev.efeed.in/api/admin0',
        'https://dev.efeed.in/api/admin1',
        'https://dev.efeed.in/api/admin2',
        'https://dev.efeed.in/api/view/filteredMap',
        'https://dev.efeed.in/api/view/methane/filteredMap',
        'https://dev.efeed.in/api/history/graph',
        'https://dev.efeed.in/api/getConstantDate',
        'https://dev.efeed.in/api/selectFarm/area',
        'https://dev.efeed.in/api/selectedArea/historyGraph/methane',
        'https://dev.efeed.in/api/selectedArea/historyGraph/ndvi',
        'https://dev.efeed.in/api/subscription/detail',
        'https://dev.efeed.in/api/farmCategory/paginate',
        'https://dev.efeed.in/api/farmCategory/userFarms/paginated',
        'https://dev.efeed.in/api/farmCategory/userFarms/dasboard',
        'https://dev.efeed.in/api/farmCategory/userFarms/centrePoints',
        'https://dev.efeed.in/api/avgSoilMoistureGraph',
        'https://dev.efeed.in/api/avgSoilMoisture',
        'https://dev.efeed.in/api/getSoilTypeData',
        'https://dev.efeed.in/api/getFarmDetail',
        'https://dev.efeed.in/api/getMilkAndCensusData',
        'https://dev.efeed.in/api/ndvi/hectors',
        'https://dev.efeed.in/api/weatherData/report',
        'https://dev.efeed.in/api/weatherHistory',
        'https://dev.efeed.in/api/chemicalData/report',
        'https://dev.efeed.in/api/animated/methane/history',
        'https://dev.efeed.in/api/animated/ndvi/history',
        'https://dev.efeed.in/api/animated/soil/history',
        'https://dev.efeed.in/api/getMethaneData',
        'https://dev.efeed.in/api/getNdviData',
        'https://dev.efeed.in/api/getAvgSoilData',
      ],
      showForeground: true,
    }),
    MatMenuModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
