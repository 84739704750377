import { Inject, Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { ApiService } from '../api/api.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class RandomGuard implements CanActivate, CanLoad {
  constructor(
    private apiService: ApiService,
    private router: Router,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}
  canActivate() {
    return this.canLoad();
  }
  async canLoad() {
    const status = await this.apiService.isLoggedIn();
    if (!status) {
      this.document.location.href = '/login';
    }
    return this.apiService.isLoggedIn();
  }
}
