<div class="delete_modal_container df ac jfs fdc">
  <div class="close_container df jsb ac">
    <div></div>
    <svg
      (click)="close()"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 6L6.5 18"
        stroke="#25282B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.5 6L18.5 18"
        stroke="#141414"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
  <div class="content_container df fdc jfs ac">
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3187_5889)">
        <path
          d="M14 10H3V12H14V10ZM14 6H3V8H14V6ZM3 16H10V14H3V16ZM21.5 11.5L23 13L16 20L11.5 15.5L13 14L16 17L21.5 11.5Z"
          fill="#FF7600"
        />
      </g>
      <defs>
        <clipPath id="clip0_3187_5889">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>

    <h2>Thankyou!</h2>
    <p>
      We have received your request, someone from our sales team will contact
      you soon in 24 hrs
    </p>
    <div class="btn_container df jc ac">
      <button type="button" class="draw_btn second" (click)="close()">
        Okay!
      </button>
    </div>
  </div>
</div>
