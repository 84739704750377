import { Inject, Injectable } from '@angular/core';
import { getAnalytics, setUserId } from 'firebase/analytics';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { catchError, timeout } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl = environment.baseUrl;
  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    @Inject(DOCUMENT) private readonly document: Document,
    private _snackBar: MatSnackBar
  ) {}
  async isLoggedIn(): Promise<boolean> {
    return this.cookieService.get('surakshit') !== '';
  }
  storeToken(token: string, rToken: string, userId: string) {
    const analytics = getAnalytics();
    setUserId(analytics, userId);
    this.cookieService.delete('surakshit');
    this.cookieService.set('surakshit', token, {
      expires: 30,
      sameSite: 'Strict',
    });
    this.cookieService.delete('surakshitBase');
    this.cookieService.set('surakshitBase', rToken, {
      expires: 30,
      sameSite: 'Strict',
    });
  }
  logout(): void {
    this.cookieService.delete('surakshit');
    this.cookieService.delete('surakshitBase');
    this.http.get(`${this.baseUrl}/users/logout`).subscribe((res: any) => {
      if (res.statusCode == 10000) {
        // this.matSnackBar.open(error.error.message, 'X', {
        //   duration: 3600,
        //   horizontalPosition: 'right',
        //   verticalPosition: 'top',
        // });
      }
    });
    this.document.location.href = '/login';
  }
  sendOtp(payload: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/users/sendMail/subscriber/sendGrid`,
      payload
    );
  }
  forgetPassword(payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/users/forget-Password`, payload);
  }
  resetPassword(payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/users/reset-Password`, payload);
  }
  googleLogin(payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/users/login/Google`, payload);
  }
  verifyOtp(payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/users/verifyOtp`, payload);
  }
  manualCreateEmail(payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/users/create/byEmail`, payload);
  }
  manualAuthenticate(payload: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/users/authenticate/byEmail`,
      payload
    );
  }

  googleSignin(payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/login/Google`, payload);
  }

  getTempCalculation(payload?: any): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/feedmaterial/tempCalculation`,
      payload
    );
  }
  calculateMethaneEmmission(payload?: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/nutriCalculator/calculate`, payload);
  }
  subacribeCalculatedForBeef(payload?: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/nutriCalculator/request/beef`,
      payload
    );
  }

  getcalculatedData(payload?: any): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/nutriCalculator/getNutriData`,
      payload
    );
  }
  getcalculatedFarmData(payload?: any): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/nutriCalculator/getFarmStimulator`,
      payload
    );
  }
  getMaterialData(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/feedmaterial`);
  }
  serverLogOut(): Observable<any> {
    return this.http.get(`${this.baseUrl}/users/logout`);
  }
  generateRefreshToken(payload?: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/users/retrieveToken`, {});
  }
  CRMQuery(payload?: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/query/raiseTicket`, payload);
  }
  sendFeedbackMessage(payload?: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/feedback`, payload);
  }

  // Profile
  getUser(): Observable<any> {
    return this.http.get(`${this.baseUrl}/users/getUserProfile`);
  }
  uploadProfileImage(payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/users/uploadProfile`, payload, {
      reportProgress: true,
      observe: 'events',
    });
  }

  updateProfile(payload?: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/users/updateProfile`, payload);
  }
  getMilkPrediction(payload?: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/nutriCalculator/getMilkPrediction`, {
      params: payload,
    });
  }
  getImageUrl(): Observable<any> {
    return this.http.get(`${this.baseUrl}/users/viewMedia`, {
      responseType: 'blob',
    });
  }

  getTestimonials(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/nutriCalculator/history`, {
      params: payload,
    });
  }

  // map methane emission api calls
  getCountryList(): Observable<any> {
    return this.http.get(`${this.baseUrl}/admin0`, {});
  }
  getStateList(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/admin1`, { params: payload });
  }
  getDistrictList(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/admin2`, { params: payload });
  }
  getPolygonData(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/view/filteredMap`, {
      params: payload,
    });
  }
  getmethaneFilteredData(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/view/methane/filteredMap`, {
      params: payload,
    });
  }
  getHistroricalData(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/history/graph`, {
      params: payload,
    });
  }
  getConstantDate(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/getConstantDate`, {
      params: payload,
    });
  }
  // saltelite map with draw option
  getSataliteFarmData(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/getFarmData/user`, {});
  }

  saveCoordinates(payload?: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/saveCoordinates/farm`, payload);
  }
  // getMethaneForPolygon(payload?: any): Observable<any> {
  //   return this.http.get(`${this.baseUrl}/selectFarm/area`, {
  //     params: payload,
  //   });
  // }
  getHistoricDataForSatelite(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/selectedArea/historyGraph/methane`, {
      params: payload,
    });
  }
  // getNVDIhistoricData(payload?: any): Observable<any> {
  //   return this.http.get(`${this.baseUrl}/selectedArea/historyGraph/ndvi`, {
  //     params: payload,
  //   });
  // }
  dashBoardCounts(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/getDashboard/counts`, {});
  }
  latest3Simulations(payload?: any): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/nutriCalculator/getLatest/Simulation`,
      {}
    );
  }
  latestFarmData(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/latest/userFarm`, {});
  }
  latestFeedData(payload?: any): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/nutriCalculator/getLatest/MethaneData`,
      {}
    );
  }
  deleteFarmSimulation(payload?: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/delete/farm/${payload}`, {});
  }
  subscriptionRequest(payload?: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/subscription/request`, payload);
  }
  getSubscriptionDetail(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/subscription/detail`);
  }
  getMapData(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/getDashboard/map`);
  }
  getMilkAndAnimalCensus(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/getMilkAndCensusData/${payload}`);
  }

  // Category

  getPaginatedCategory(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/farmCategory/paginate`, {
      params: payload,
    });
  }
  getPaginatedCategoryFarms(payload?: any, categoryId?: any): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/farmCategory/userFarms/paginated/${categoryId}`,
      { params: payload }
    );
  }
  addCategory(payload?: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/farmCategory`, payload);
  }

  categoryDashBoardCount(payload?: any): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/farmCategory/userFarms/dasboard/${payload}`
    );
  }
  categoryCenterPoints(payload?: any): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/farmCategory/userFarms/centrePoints/${payload}`
    );
  }
  getSingleCategory(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/farmCategory/${payload}`);
  }
  getSingleCategoryByFarmId(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/getFarmDetail/${payload}`);
  }
  deleteSingleCategory(payload?: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/farmCategory/delete/${payload}`, {});
  }
  deleteMultipleCategory(payload?: any, farmIds?: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/farmCategory/delete/farms/${payload}`,
      farmIds
    );
  }
  editSingleCategory(id?: string, payload?: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/farmCategory/update/${id}`, payload);
  }
  categoryFarmStatusProgress(): Observable<any> {
    return this.http.get(`${this.baseUrl}/farmCategory/getProgress/status`);
  }
  // Soil module
  avgSoilMoistureGraph(id?: string, payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/avgSoilMoistureGraph/${id}`, {
      params: payload,
    });
  }
  // avgSoilMoisture(id?: string, payload?: any): Observable<any> {
  //   return this.http.get(`${this.baseUrl}/avgSoilMoisture/${id}`, {
  //     params: payload,
  //   });
  // }
  getSoilTypeData(id?: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSoilTypeData/${id}`);
  }

  getNVDIHector(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/ndvi/hectors`, {
      params: payload,
    });
  }

  getWeatherData(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/weatherData/report`, {
      params: payload,
    });
  }

  getWeatherHistory(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/weatherHistory`, {
      params: payload,
    });
  }
  getChemicalData(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/chemicalData/report`, {
      params: payload,
    });
  }

  // Map new api's

  getMethaneForPolygon(payload?: any): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/animated/methane/history`, {
        params: payload,
      })
      .pipe(
        timeout(600000),
        catchError((error) => {
          this._snackBar.open(error.message, 'X', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 3600,
          });
          return error;
        })
      );
  }
  getMethaneData(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/getMethaneData`, {
      params: payload,
    });
  }
  getNVDIhistoricData(payload?: any): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/animated/ndvi/history`, {
        params: payload,
      })
      .pipe(
        timeout(600000),
        catchError((error) => {
          this._snackBar.open(error.message, 'X', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 3600,
          });
          return error;
        })
      );
  }
  getNdviData(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/getNdviData`, {
      params: payload,
    });
  }
  avgSoilMoisture(payload?: any): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/animated/soil/history`, {
        params: payload,
      })
      .pipe(
        timeout(600000),
        catchError((error) => {
          this._snackBar.open(error.message, 'X', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 3600,
          });
          return error;
        })
      );
  }
  getAvgSoilData(payload?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/getAvgSoilData`, {
      params: payload,
    });
  }
}
