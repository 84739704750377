import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from '../api/api.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  constructor(
    private readonly cookieService: CookieService,
    private readonly apiService: ApiService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.cookieService.get('surakshit') ?? '';
    const rtoken = this.cookieService.get('surakshitBase') ?? '';
    req = this.AddTokenheader(req, token, rtoken);
    return next.handle(req).pipe(
      tap((event: any) => {
        if (event instanceof HttpResponse) {
          if (event.body.errorCode == 303) {
            this.handleRefrehToken(req, next);
          }
        }
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleRefrehToken(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      return this.apiService.generateRefreshToken().subscribe(
        (res) => {
          if (res.statusCode == 10000) {
            this.apiService.storeToken(
              res.data.token,
              res.data.refreshToken,
              res.data.userId
            );
            next.handle(
              this.AddTokenheader(req, res.data.token, res.data.refreshtoken)
            );
            this.document.location.reload();
          }
        },
        (error) => {
          this.apiService.logout();
        }
      );
    } else {
      return req.clone({
        setHeaders: {
          // device: 'android',
          // version: '84',
        },
      });
    }
  }
  AddTokenheader(request: HttpRequest<any>, token: any, rToken: any) {
    return request.clone({
      setHeaders: {
        // device: 'android',
        // version: '84',
        Authorization: 'Bearer ' + token,
        Refreshtoken: rToken,
      },
    });
  }
}
