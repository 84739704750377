// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://dev.efeed.in/api',
  // baseUrl: 'http://127.0.0.1:3000/api',
  firebaseConfig: {
    apiKey: 'AIzaSyAlkB6WXL2CE1hZ1kYZlxFGhBDa13-t3d4',
    authDomain: 'vetvantage-dashboard.firebaseapp.com',
    projectId: 'vetvantage-dashboard',
    storageBucket: 'vetvantage-dashboard.appspot.com',
    messagingSenderId: '1072401590078',
    appId: '1:1072401590078:web:2b52f8b7755a24f4256e6f',
    measurementId: 'G-EHGW91RJ29',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
