import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vetvantage-thanks-modal',
  templateUrl: './thanks_modal.component.html',
  styleUrl: './thanks_modal.component.scss',
})
export class ThanksModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ThanksModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}
  close() {
    this.dialogRef.close();
  }
}
