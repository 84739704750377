import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private message = new BehaviorSubject(false);
  getMessage = this.message.asObservable();

  private messageScrollTop = new BehaviorSubject(false);
  getmessageScrollTop = this.messageScrollTop.asObservable();

  private messageProfile = new BehaviorSubject(false);
  getmessageProfile = this.messageProfile.asObservable();

  private subscription = new BehaviorSubject('');
  getSubscription = this.subscription.asObservable();

  // for loader
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  // for loader
  private errorSubject = new BehaviorSubject<boolean>(false);
  errorModal$ = this.errorSubject.asObservable();
  constructor() {}
  setMessage(data: any) {
    this.message.next(data);
  }

  setMessageForScrollTop(data: any) {
    this.messageScrollTop.next(data);
  }
  setMessageForForProfile(data: any) {
    this.messageProfile.next(data);
  }
  setSubscription(data: any) {
    this.subscription.next(data);
  }

  convertAmethaneTons(methane: any, surfaceArea: any) {
    const data = (16.04 * methane * surfaceArea) / 100000000000;
    return data.toFixed(3);
  }
  // for loading
  show() {
    this.loadingSubject.next(true);
  }

  hide() {
    this.loadingSubject.next(false);
  }
  // for error
  errorshow() {
    this.errorSubject.next(true);
  }

  errorhide() {
    this.errorSubject.next(false);
  }
}
